$theme: #ff4200;
$secondary: #339947;
$background: #F4F3EA;
$yellow: #FFDD00;
$orange: #F16522;
$orange-dark: #C14B27;
$gray: #999999;
$light-gray: #cccccc;
$dark-gray: #666666;
$white: #ffffff;
$green: #7ccb00;
$beige: #eddebd;
$black: #000;

$default-font: 'Open Sans', sans-serif;
$condensed-font: 'Open Sans', sans-serif;

$width: 100%;
$gutter: 0;

$breakpoint-phone: 360px;
$breakpoint-phone-alt: 450px;
$breakpoint-tablet: 728px;
$breakpoint-desktop: 1024px;
$breakpoint-intermediate: 1280px;
$breakpoint-high: 1500px;
$breakpoint-note: 1380px;

$breakpoint-default: $breakpoint-desktop;

// 0 - 727
$small:(max-width: $breakpoint-tablet - 1);

// 728 - 1023
$medium: "(min-width: #{$breakpoint-tablet}) and (max-width: #{$breakpoint-desktop - 1})";

// 1024
$large: (min-width: $breakpoint-desktop);

// 320 up
$small-up: (min-width: $breakpoint-phone + 1);

// 320 down
$small-down: (max-width: $breakpoint-phone);

// 450 down
$small-down-alt: (max-width: $breakpoint-phone-alt);

// 728 up
$medium-up: (min-width: $breakpoint-tablet);

// 1023 down
$large-down: (max-width: $breakpoint-desktop);

// 1024 up
$large-up: (min-width: $breakpoint-desktop);

//1280 down
$intermediate-down: (max-width: $breakpoint-intermediate);

$notebook-down: (max-width: $breakpoint-note);

//1280 down
$high-down: (max-width: $breakpoint-high);

@import "./variables.scss";

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;800&display=swap');

* {margin: 0; padding: 0;}
*, ::before, ::after {-webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box;}

html {overflow-y: scroll; overflow-x: hidden; }
body {background: $white; height: 100%;}
body, input, textarea, button {font-family: $default-font; font-size: 12px; color: #000;}
strong, b {font-weight: 700;}
div, ul {scrollbar-width: thin;}
a { text-decoration: none; display: inline-block; }

.row, .column {display: flex;}
.row {flex-direction: row;}
.column {flex-direction: column;}

.aic {align-items: center;}
.jcc {justify-content: center;}
.tac { text-align: center; }

.d-block { display: block }
.d-i-block { display: inline-block; }
.d-none { display: none }
.flex-wrap { flex-wrap: wrap; }
.flex-no-wrap { flex-wrap: nowrap; }
.align-items-center { align-items: center; }
.align-items-start { align-items: flex-start; }
.align-items-end { align-items: flex-end; }
.justify-content-between { justify-content: space-between; }
.justify-content-center { justify-content: center; }
.justify-content-end { justify-content: flex-end; }
.justify-content-start { justify-content: flex-start; }
.justify-space-around { justify-content: space-around; }
.flex-shrink-1 { flex-shrink: 1; }
.flex-shrink-0 { flex-shrink: 0; }
.flex-grow-1 { flex-grow: 1; }
.flex-grow-0 { flex-grow: 0; }
.w-100 { width: 100%; }

.order-1 { order: 1; }
.order-2 { order: 2; }
.order-3 { order: 3; }
.order-4 { order: 4; }
.order-5 { order: 5; }

@font-face {
    font-family: 'madetommy';
    src: url('../assets/fonts/MADETOMMY-Medium.eot');
    src: url('../assets/fonts/MADETOMMY-Medium.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/MADETOMMY-Medium.woff2') format('woff2'),
        url('../assets/fonts/MADETOMMY-Medium.woff') format('woff'),
        url('../assets/fonts/MADETOMMY-Medium.ttf') format('truetype'),
        url('../assets/fonts/MADETOMMY-Medium.svg#MADETOMMY-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'madetommy';
    src: url('../assets/fonts/MADETOMMY-ExtraBold.eot');
    src: url('../assets/fonts/MADETOMMY-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/MADETOMMY-ExtraBold.woff2') format('woff2'),
        url('../assets/fonts/MADETOMMY-ExtraBold.woff') format('woff'),
        url('../assets/fonts/MADETOMMY-ExtraBold.ttf') format('truetype'),
        url('../assets/fonts/MADETOMMY-ExtraBold.svg#MADETOMMY-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'madetommy';
    src: url('../assets/fonts/MADETOMMY-Light.eot');
    src: url('../assets/fonts/MADETOMMY-Light.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/MADETOMMY-Light.woff2') format('woff2'),
        url('../assets/fonts/MADETOMMY-Light.woff') format('woff'),
        url('../assets/fonts/MADETOMMY-Light.ttf') format('truetype'),
        url('../assets/fonts/MADETOMMY-Light.svg#MADETOMMY-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'madetommy';
    src: url('../assets/fonts/MADETOMMY.eot');
    src: url('../assets/fonts/MADETOMMY.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/MADETOMMY.woff2') format('woff2'),
        url('../assets/fonts/MADETOMMY.woff') format('woff'),
        url('../assets/fonts/MADETOMMY.ttf') format('truetype'),
        url('../assets/fonts/MADETOMMY.svg#MADETOMMY') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'madetommy';
    src: url('../assets/fonts/MADETOMMY-Bold.eot');
    src: url('../assets/fonts/MADETOMMY-Bold.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/MADETOMMY-Bold.woff2') format('woff2'),
        url('../assets/fonts/MADETOMMY-Bold.woff') format('woff'),
        url('../assets/fonts/MADETOMMY-Bold.ttf') format('truetype'),
        url('../assets/fonts/MADETOMMY-Bold.svg#MADETOMMY-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'madetommy';
    src: url('../assets/fonts/MADETOMMY-Black.eot');
    src: url('../assets/fonts/MADETOMMY-Black.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/MADETOMMY-Black.woff2') format('woff2'),
        url('../assets/fonts/MADETOMMY-Black.woff') format('woff'),
        url('../assets/fonts/MADETOMMY-Black.ttf') format('truetype'),
        url('../assets/fonts/MADETOMMY-Black.svg#MADETOMMY-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'madetommy';
    src: url('../assets/fonts/MADETOMMY-Thin.eot');
    src: url('../assets/fonts/MADETOMMY-Thin.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/MADETOMMY-Thin.woff2') format('woff2'),
        url('../assets/fonts/MADETOMMY-Thin.woff') format('woff'),
        url('../assets/fonts/MADETOMMY-Thin.ttf') format('truetype'),
        url('../assets/fonts/MADETOMMY-Thin.svg#MADETOMMY-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@media #{$large-down} {
  .d-m-block { display: block }
  .d-m-i-block { display: inline-block; }
  .d-m-none { display: none }
  .d-m-row { display: flex; }
  .flex-m-column { flex-direction: column; }
  .flex-m-column-reverse { flex-direction: column-reverse; }
  .flex-m-wrap { flex-wrap: wrap; }
  .flex-m-nowrap { flex-wrap: nowrap; }
  .align-items-m-center { align-items: center; }
  .align-items-m-start { align-items: flex-start; }
  .align-items-m-end { align-items: flex-end; }
  .justify-content-m-between { justify-content: space-between; }
  .justify-content-m-start { justify-content: flex-start; }
  .justify-content-m-end { justify-content: flex-end; }
  .justify-content-m-center { justify-content: center; }

  .flex-m-shrink-1 { flex-shrink: 1; }
  .flex-m-shrink-0 { flex-shrink: 0; }
  .flex-m-grow-1 { flex-grow: 1; }
  .flex-m-grow-0 { flex-grow: 0; }

  .w-m-100 { width: 100%; }

  .order-m-1 { order: 1; }
  .order-m-2 { order: 2; }
  .order-m-3 { order: 3; }
  .order-m-4 { order: 4; }
  .order-m-5 { order: 5; }
}

.gray {color: $gray;}
.light-gray {color: $light-gray;}

.object-contain {
    object-fit: contain;
    width: 100%;
}

.object-cover {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.text {
  font-size: 16px;
  color: #000;
  line-height: 1.4;

  p {
    margin: 0 0 5px;
  }
}

.loading {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background: rgba(0,0,0,.7) url(../assets/loading.svg) center no-repeat;
    display: none;

    &.show {
        display: block;
    }
}

.msg {
    padding: 15px;
    margin: 15px 0;
    display: flex;
    align-items: center;

    &.error {
        border: 1px #f5c6cb solid;
        background: #f8d7da;
        color: #b62433;
        text-align: left;
    }

    &.alert {
        border: 1px #FFFF33 solid;
        border-color: #FFFF33;
        color: #999900;
        background: rgba(153,153,0, 0.4);
    }

    &.success {
        border: 1px #28a745 solid;
        border-color: #28a745;
        color: #446d4d;
        background: rgba(40, 167, 69, 0.4);
    }
}

@media #{$small} {
    .hidden-on-mobile {
        display: none !important;
    }
}

@media #{$medium} {
    .hidden-on-tablet {
        display: none !important;
    }
}

@media #{$large} {
    .hidden-on-desktop {
        display: none !important;
    }
}

textarea,
input.text,
input[type="text"],
input[type="button"],
input[type="submit"],
input[type="email"],
input[type="number"],
input[type="tel"],
input[type="reset"],
input[type="checkbox"],
input[type="radio"],
.input-checkbox {
-webkit-appearance: none;
border-radius: 0;
}

.material-icons {
    font-family: "Material Icons" !important;
    font-weight: normal;
    font-style: normal;
    font-size: 18px; /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
  
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
  
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
  }
@import '../../styles/variables.scss';

.bubble {
  background: $white;
  border-radius: 10px;
  display: inline-block;
  padding: 3px 6px;
  margin: 1.5px;
  cursor: pointer;
  border: 1px $white solid;

  &:focus {
    outline: 0;
  }

  &:hover {
    background: $orange;

    & .bubble__text {
      color: $white;
    }
  }

  &__text {
    color: $theme;
    font-size: 11px;
  }
}

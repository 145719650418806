@import "../../styles/variables.scss";

$totalEle: 10;
$duration:10s;
$speed: $duration / ($totalEle / 2);
$delay: $speed / 2;
$default-bg-color: black;
$complete-on: $duration + $delay + 0.5s;

.fastbingo {
  
  .countdown {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background: rgba(0,0,0,.6);

    &::before {
      content: "";
      color: #FFF;
      width: 100px;
      text-align: center;
      animation: countdown 4s step-end 0s 1;
      animation-fill-mode: forwards;
      font-size: 16vw;
      text-shadow: 0 0 7px #fff,
        0 0 10px #fff,
        0 0 42px #ff4a5e,
        0 0 92px #ff4a5e;
    }
  }

  @keyframes countdown {
    0% {
      content: "3";
    }
    25% {
      content: "2";
    }
    50% {
      content: "1";
    }
    75% {
      content: "0";
    }
    100% {
      content: "0";
    }
  }

  .totalDrawNumbers {
    text-align: center;
    margin: 0 0 35px;
    color: #ff4a5e;
    font-size: 25px;
  }

  .wrap-loading {
    width: 100%; 
    height: 100%; 
    top: 0; 
    left: 0; 
    position: fixed; 
    background: rgba(0,0,0,.6) url(../../assets/loading.svg) center no-repeat; 
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center; 

    h2{
      font-size: 20px;
      color: #FFF;
      margin-top: 230px;
     }
  }

  .wrap-cards {
    padding: 0 50px 0 0;
  }

  .winners {
    min-height: 250px;
    color: #FFF;
    font-size: 16px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .type {
      margin: 5px 0;
    }

    span {
      font-weight: bold;
      margin-bottom: 20px;
    }
  }

  .big-number-draw {
    width: 100%;
    min-height: 250px;
    
    span {
      font-size: 78px;
      text-align: center;
      border: 5px solid #ff4a5e;
      color: #fff;
      border-radius: 50%;
      width: 200px;
      line-height: 200px;
      margin: 0 0 30px;
      display: block;
      opacity: 0;
      transition: all .25s;
      transform: translateX(-10px);
      text-shadow: 0 0 7px #fff,
      0 0 10px #fff,
      0 0 42px #ff4a5e,
      0 0 92px #ff4a5e;
      filter: 
      drop-shadow(0 0 5px #ff4a5e) drop-shadow(0 0 20px #ff4a5e);

      &.green {
        filter: drop-shadow(0 0 5px #3cb037) drop-shadow(0 0 20px #3cb037);
        border-color: #3cb037;
        text-shadow: 0 0 7px #fff,
          0 0 10px #fff,
          0 0 42px #3cb037,
          0 0 92px #3cb037;
      }

      &.blue {
        filter: drop-shadow(0 0 5px #1c99e2) drop-shadow(0 0 20px #1c99e2);
        border-color: #1c99e2;
        text-shadow: 0 0 7px #fff,
          0 0 10px #fff,
          0 0 42px #1c99e2,
          0 0 92px #1c99e2;
      }

      &.active {
        transform: translateX(0);
        opacity: 1;
      }

      &.reveal-out {
        transform: translateX(10px);
        opacity: 0;
      }

    }
  }

  .main-list-card {

    .col {
      width: calc(100% / 15);

      .number {
        opacity: .25;
        font-size: 22px;
        font-weight: 600;
        text-align: center;
        transition: all .25s ease-out .25s;
  
        &.color-red {
          color: #ff4a5e;
        }
  
        &.color-blue {
          color: #1c99e2;
        }
  
        &.color-green {
          color: #3cb037;
        }

        &.active {
          opacity: 1;
        }
      }
    }

  }

  .container {
    padding: 35px;
    min-height: 610px;
  }

  .buy-cards {

    display: grid;
    grid-template-columns: repeat(2, 160px);
    grid-gap: 30px;
    justify-content: center;
    margin-top: 15px;

    .item {
      height: 100px;
      background: #7ccb00;
      margin: 0 0 25px;
      cursor: pointer;
      border-radius: 10px;

      h3 {
        text-align: center;
        font-size: 20px;
        line-height: 1.2;
        font-weight: 800;
        color: #FFF;
      }
    }
  }

  .logoFastbingo {
    display: block;
    max-width: 80%;
    min-height: 50px;
    object-fit: contain;
    margin: 0 0 30px;
  }

  .item {
  
    img {
      max-height: 100px;
      max-width: 80%;
      display: block;
      margin: 0 0 15px;
    }

    .value {
      font-size: 15px;
      color: #FFF;
      font-weight: 600;
      text-align: right;
      margin: 0 15px 0 0;

      img {
        display: block;
        margin: 0 0 0 10px;
        border-radius: 0;
      }
    }

    .input {
      width: 60px;
      height: 70px;
      background: #FFF;
      border: none;
      text-align: center;
      font-size: 30px;
      color: #545450;
      font-weight: 600;
    }
  }

  .wrapComprarCartela {
    position: relative;


    .error {
      background-color: #fff;
      padding: 10px 20px;
      width: 100%;
      color: #ff4a5e;
      margin: 10px 0;
      border-radius: 10px;
      text-align: center;
      font-size: 12px;
      font-weight: bold;
    }

    .close {
      position: absolute;
      width: 40px;
      line-height: 40px;
      color: #FFF;
      font-weight: 600;
      margin: 0 0 25px;
      font-size: 24px;
      cursor: pointer;
      display: block;
      text-align: center;
      border-radius: 5px;
      border: 2px solid #FFF;
    }
  }

  .button {
    line-height: 50px;
    width: 100%;
    display: block;
    background: #7ccb00;
    border:  none;
    
    &.start-game {
      margin: 25px 0 0;
    }

    span {
      font-size: 28px;
      font-weight: 600;
      color: #FFF;
      text-align: center;

    }
  }

  .endGamePopup {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background: rgba(0,0,0,.8);
    display: flex;
    justify-content: center;
    align-items: center;
  
    img {
      display: block;
      max-width: 80%;
      max-height: 90%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .text {
      text-align: center;
      color: #1D1D1B;
      position: absolute;
      font-size: 20px;
      font-weight: 500;
      top: calc(50% + 5vw);
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @media #{$large-down} {

    .endGamePopup {
      .text {
        font-size: 12px;
        line-height: 12px;
        margin: 16px 0;
      }
    }

    .container {
      padding: 25px 15px;

      .wrap-cards {
        padding: 0;
      }
      
      .totalDrawNumbers {
        font-size: 19px;
        margin: 0 0 20px;
      }
  
      .big-number-draw {
        min-height: 150px;
  
        span {
          font-size: 58px;
          width: 100px;
          line-height: 100px;
        }
      }

      .buy-cards {
        grid-gap: 20px;
        grid-template-columns: repeat(2, 140px);
  
        .item {
          margin: 0;
  
          h3 {
            font-size: 17px;
          }
        }
      }
  
      .wrapComprarCartela {
        .close {
          margin: 0;
          right: -10px;
        }
      }
  
      .logoFastbingo {
        display: block;
        margin: 25px auto;
      }

      .section-cards {
        display: flex;
        flex-direction: column-reverse;
      }
  
      .main-list-card  {
  
        .col {
          margin: 0 0 10px;
  
          .number {
            font-size: 16px;
          }
        }
      }
  
      .button {
  
        span {
          font-size: 24px;
        }
      }
    }

    .countdown {

      &::before {
        font-size: 22vw;
      }
    }

  }

}
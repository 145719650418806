@import "../../styles/variables.scss";

.winnerPopup {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(0,0,0,.8);
  display: flex;
  justify-content: center;
  align-items: center;

  p{
    color: white;
    margin-top: 220px;
    font-size: 15px;
    z-index: 10;
    max-width: 250px;
    text-align: center;
    overflow: hidden;
    height: 65px;

    @media #{$small} {
      max-width: 130px;
      margin-top: 85px;
      height: 28px;
      font-size: 9px;
      line-height: 9px;
    }

  }

  img {
    display: block;
    max-width: 80%;
    max-height: 90%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
@import "../../styles/variables.scss";

.fastbingo {
  
  &.fastbingo-v1 {
    background: url(../../assets/bg-fastbingo.jpg) top center no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }

}
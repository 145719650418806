@import "../../styles/variables.scss";

.chat {
  height: 565px;
  max-height: calc(100% - 10px);
  padding: 5px 0 10px 25px;
  display: flex;
  flex-direction: column;
  width: calc(100% - 50px);
  margin: 0;

  .timeline {
    padding: 3px 3px 3px 10px;
    overflow-x: hidden;
    overflow-y: scroll;
    transition: all .25s;
    margin: 15px 0 0 0;
    background: #FFF;
    height: 100%;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: #DDD;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #999;
      border-radius: 0;
      border: none; 
    }
  }

  .wrap-talk {
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 0;
    margin-bottom: 0;
    padding: 0;
    position: relative;
    width: calc(100% + 30px);

    .show-messages {
      background: #feeeec;
      padding: 3px 5px;
      border-radius: 5px 5px 0 0;
      position: absolute;
      right: 15px;
      z-index: 9;
      top: -15px;
      cursor: pointer;

      img {
        width: 20px;
        margin: 0 10px 0 0;
      }

      .arrow {
        width: 0;
        height: 0;
        display: block;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        border-top: 10px solid #ff4200;
        margin: 0 0 0 10px;
      }
    }

    .talk {
      background: #feeeec;
      height: 100%;
      width: 100%;
      padding: 10px;
      overflow: auto;
    }
  
  }

  @media #{$large-down} {

    width: calc(100% - 25px);
    padding: 5px 0 5px 25px;

    .timeline {
      margin: 0;
      padding-bottom: 10px;
      height: 90%;
    }

    .wrap-talk {
      width: calc(100% + 10px);    

    }

  }

  /*
  @media #{$small} {

    .timeline {
      height: calc(100% - 75px);
    }

    .wrap-talk {
      height: 60%;
      bottom: 60%;
      width: 80%;
    }
  }
  */
}

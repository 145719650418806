@import "../../styles/variables.scss";

.card {
  width: 100%;
  border-radius: 3px;
  background: #FFECA9;
  font-family: $condensed-font;

  .prize { 
    color: $theme;
  }

  &.winner {
    background: $green;
  }

  &.bingo-label-foot {
    background: #BDC241;
    padding: 3px 5px;
    display: flex;
    justify-content: space-between;
    height: 23px;

    span {
      color: $white;
    }
  }

  &.bingo-label {
    padding-top: 3px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 5px 2px;
    font-size: 11px;
    @media #{$large-down} {
      font-size: 10px;
    }

    .text {
      padding: 3px;
      color: #FFF;
      font-weight: 800;
      background: #BDC241;
      display: inline-block;
      font-size: 13px;
      line-height: .9;

      @media #{$large-down} {
        font-size: 11px;
      }
    }

    span{
      color: $dark-gray;
    }

    .icon {
      display: flex;
      align-items: center;
      font-weight: 800;
      font-size: 16px;
      color: #41266A;

      img{
        width: 15px;
        height: 15px;
        margin-right: 3px;
      }
    }

  }

  &.bingo-label {
    &.loser {
      color: $orange-dark;

      span{
        color: $orange-dark;
      }
    }
    &.winner {
      color: $white;

      span{
        color: $white;
      }

    }
  }

  &__table {
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    grid-auto-flow: column;
    padding: 3px 3px 3px;

    &.winner {
      background-color: $green;
      
      .card__table__number  {
        background-color: $green;
      }

    }

    &.loser {
      .card__table__number  {
        background-color: $white;
        color: $light-gray;
      }

    }

    &.card {
      & .active {
        background-color: #41266A;

        span {
          color: #FFF;
        }
      }
      & .good-one {
        background-color: $green;
      }

      & :nth-child(5n) {
        border-bottom: none;
      }

      @for $i from 21 through 25 {

        & :nth-child(#{$i}) {
          border-right: none;
        }

      }

      & :nth-child(13) {
        background: url(../../assets/logo-bingotine-small.png) center no-repeat #41266A;
        background-size: 23px auto;
      }

    }

    &__number {
      min-height: 23px;
      display: flex;
      justify-content: center;
      align-items: center;
      //background: $gray;
      color: #1D1D1B;
      font-size: 15px;
      min-width: 20px;
      font-weight: 800;
      border-bottom: 2px solid #1D1D1B;
      border-right: 2px solid #1D1D1B;

      @media #{$large-down} {
        font-size: 13px;
      }

      span {
        cursor: default;
        user-select: none;
      }

      &.winner-line {
        background: $green !important;
      }

      &.winner-corner {
        background: $green !important;
      }
      
      &.click-ball-screen {
        cursor: pointer;
        position: relative;
        overflow: hidden;

        span {
          cursor: pointer;
        }

        svg {
          height: 100%;
          max-width: 18px;
        }

        &:hover {
          span {
            z-index: 1;
            color: #FFF;
          }
          &:before {
            content: '';
            background: #A80094;
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 0;
          }
        }
      }

      &.ball-screen {
        overflow: hidden;
        border-radius: 0;
        background: none;
        position: relative;
        height: 0;
        width: 100%;
        height: 100%;
        margin: auto;

        &:before {
          content: '';
          background: #A80094;
          width: 100%;
          height: 100%;
          position: absolute;
          border-radius: 0;
          transition: opacity .5s .3s;
          z-index: 0;
        }
        
        span {
          z-index: 1;
          color: #FFF;
        }

        &.winner {
          &:before {
            content: none;
          }
          background: $green;
        }
      }

      @media #{$small-down} {
        font-size: 13px;
      }

    }
  }
}

@import "../../../styles/variables.scss";

.modal-global {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 110;

    &--open {
        display: block;
    }

    &-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.5);
    }

    &-content {
        position: absolute;
        background: #f4f4e9;
        top: 70px;
        left: 0;
        width: 100%;
        height: calc(100% - 70px);
        overflow: auto;

        @media #{$large-down} {
            width: 100%;
            height: calc(100% - 60px);
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        @media #{$small} {
            height: 100%;
        }

        &-close {
            position: absolute;
            top: 0;
            right: 0;
            font-size: 20px;
            cursor: pointer;
            width: 40px;
            min-height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;

            @media #{$large-down} {
                position: sticky;
                left: calc(100% - 40px);
                right: auto;
            }
        }
    }

}

@import "../../styles/variables.scss";

.input-numeric {
  display: grid;
  margin: 15px auto 20px;
  max-width: 115px;
  grid-template-columns: auto 20px;
  grid-template-rows: 35px 35px;
  grid-template-areas:
      "input arrow-top"
      "input arrow-bottom";

  input {
    display: block;
    grid-area: input;
    border: none;
    outline: none;
    -moz-appearance: textfield;

    &:focus {
      box-shadow: inset 0 1px 0 0 $gray;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    
  }

  .arrow {
    border-radius: 0;
    border: none;
    background-color: $beige;
    cursor: pointer;
    position: relative;

    &:disabled {
      opacity: .5;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }

    &--top {
      &::before {
        border-bottom: 6px solid $dark-gray;
      }
    }

    &--bottom {
      &::before {
        border-top: 6px solid $dark-gray;
      }
    }
  }
}

.input {
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid #808080;
  color: #7b7b7b;
  font-size: 18px;
  font-weight: 600;
  background: transparent;
  padding: 0 10px;
  font-family: "Open Sans", sans-serif;

  &:focus,
  &:focus-visible {
    outline: 0;
  }

  &.btn {
    width: 100%;
    flex-shrink: 0;
    line-height: 40px;
    border-radius: 10px;
    background: #4f0b85;
    text-align: center;
    color: #FFF;
    font-size: 24px;
    font-weight: 600;  
    border: none;
    cursor: pointer;
  
  }

  @media #{$high-down} {

    &.btn {
      font-size: 20px;
    }
  }
}

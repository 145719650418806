@import '../../styles/variables.scss';

.chatMessage {
    display: flex;
    align-items: center;
    padding: 3px 0;
    direction: ltr;

    &__avatar {
        min-width: 33px;
        min-height: 33px;
        background: lightblue;
        border: 1px $gray solid;
        border-radius: 3px;
        overflow: hidden;
        margin: 0 10px 0 0;
        background-size: contain;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        align-self: flex-start;

        .icon {
            padding: 1px;
            width: 15px;
            height: 15px;
        }

    }

    span {
        opacity: .8;
    }

    &__text {
        color: $black;
        font-size: 13px;
        line-height: 1;
    }

    &__name {
        color: $gray;
        font-size: 11px;
        line-height: 10px;
        display: block;
        margin: 0 0 5px;
    }

    @media #{$large-down} {

        &__avatar {
            width: 25px;
            height: 25px;

        }

        &__text {
            font-size: 12px;
        }

        &__name {
            font-size: 10px;
        }
    }
}

@import '../../../styles/variables.scss';

.wlast-balls {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        text-align: center;
        font-weight: 800;
        margin: 0 0 30px;
        font-size: 28px;
    }

    h3 {
        font-size: 15px;
        margin-bottom: 15px;
    }

    .balls {
        overflow: hidden;
        display: flex;

        .ball {
            font-size: 28px;
            font-weight: 800;

            span {
                user-select: none;
                cursor: default;
            }
        }

    }

    @media #{$large-down} {

        .balls {
    
            .ball {
              display: none;
      
              &.ball1,
              &.ball2,
              &.ball3 {
                display: flex;
                font-size: 22px;
              }
            }
          }

    }
}
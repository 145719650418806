@import "./variables.scss";

$base-column:  4;

@for $i from 1 through 25 {
  $width: $base-column * $i + '%';
    .col {
      padding: 0 10px 0 0;
      position: relative;
      min-height: 1px;
      margin: 0 0 20px;
      flex-shrink: 0;
      flex-grow: 1;

      &-#{$i} {
        width: #{$width};
      }

      &-last {
        padding: 0;
      }
    }
}


.col-margin-0 { 
  margin: 0;
}

@media #{$large-down} {
  .col-m-padding-0{
    padding: 0;
  }
  
  .col-m-margin-0 { 
    margin: 0;
  }
  
  .col-m {
    padding: 0 15px 0 0;
  }
  
  @for $i from 1 through 25 {
    $width: $base-column * $i + '%';
  
    .col {
      &-m {
        &-#{$i} {
          width: #{$width};
        }
    
        &-last {
          padding: 0;
        }
      }
    }
  }
}

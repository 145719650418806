@import '../../styles/variables.scss';

.button {
  border: 0;
  border-radius: 10px;
  cursor: pointer;
  transition: all .2s;

  &:disabled {
    cursor: default;
    background: rgba($dark-gray, 0.5) !important;
    border-color: rgba($dark-gray, 0) !important;

    .button__text {
      color: $white !important;
    }
  }

  &__text {
    color: $white;
    font-family: $condensed-font;
    font-weight: bold;
    transition: all .2s;
  }

  &--primary {
    background: $theme;
    border: 2px $theme solid;

    &:hover {
      svg circle {
        fill: #FFF;
      }
    }

  }

  &--secondary {
     background: $secondary;
     border: 2px $secondary solid;
     padding: 0 20px;

    &:hover {
      svg circle {
        fill: #FFF;
      }
    }
  }

  &--orange {
    background: $orange;
    border: 2px $orange solid;

    &:hover {

      svg circle {
        fill: #FFF;
      }
    }
  }

  &--yellow {
    background: $yellow;
    border: 2px $yellow solid;

    &:hover {

      svg circle {
        fill: #FFF;
      }
    }
  }

  svg {
    display: block;
    max-width: 100%;
    max-height: 80%;
    margin: auto;

    circle {
      fill: $white;
    }
  }
}

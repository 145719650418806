@import '../../../styles/variables.scss';

span.counter {
    font-size: 40px;
    color: $white;
    font-weight: bold;
    z-index: 9;

    @media #{$small} {
        font-size: 25px;
        
    }
}
@import "./Videobingo.scss";

.theme-cachorro {
  padding: 50px 0;
  position: relative;

  .container {
    padding: 0 0 0 15px;
  }
  

  @media #{$large-down} {
    padding: 15px 0 50px;
  }

  .margin_auto_balls {
    margin: 30px 0 auto;
  }

  .bg-video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
  }
  

  .wrap-chat {
    flex-shrink: 0;
    width: 440px;
    height: 610px;
    /*
    background: url(../../assets/tema-cachorro/bg-chat.png) center no-repeat;
    background-size: 100% auto;
    */
    position: relative;
    padding: 30px 0 40px;

    &::after {
      content: '';
      position: absolute;
      left: -27px;
      height: calc(100% - 114px);
      width: 54px;
      background: url(../../assets/tema-cachorro/b1.png);
      background-size: 100% 100%;
      top: 54px;
    }

    &::before {
      content: '';
      position: absolute;
      right: 0;
      height: calc(100% - 114px);
      width: 52px;
      background: url(../../assets/tema-cachorro/b2.png);
      background-size: 100% 100%;
      top: 54px;
    }


    .top { 
      position: absolute; 
      width: calc(100% + 15px); 
      height: 54px; 
      background: url(../../assets/tema-cachorro/t1.png);
      background-size: 100% 100%;
      top: 0;
      left: -15px;
      z-index: 2;
    }

    .bottom { 
      position: absolute; 
      width: 100%; 
      height: 60px; 
      background: url(../../assets/tema-cachorro/t2.png);
      background-size: 100% 100%;
      bottom: 0;
      left: 0;
      z-index: 2;
    }
  }

  .wlast-balls {

    h1 {
      color: #05f6ff;

      span {
        color: #faae00;
      }
    }

    h3 {
      color: #fff;
    }

    .balls {
      padding: 0;
      width: 19.79vw;

      .ball {
        width: 4.5vw;
        height: 7.15vw;
        background: url(../../assets/tema-cachorro/small-ball.png) center no-repeat;
        background-size: 100% auto;  
        color: #89ff06;

        &.ball1 {
          width: 5.75vw;
          height: 7.15vw;
          color: #49b1e2;
          background: url(../../assets/tema-cachorro/big-ball.png) center no-repeat;
          background-size: 100% auto;
        }
      }
    }
  }

  .box-prix {
    padding: 10px 30px 20px;
    position: relative;
    margin: 25px 0;
    min-height: 300px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    user-select: none;

    &.box-prix-buy {
      margin: 25px 0 auto;
      @media #{$large-down} { 
        margin: 0;
      }
    }

    &.sound {
      min-height: auto;
      margin: 0 auto;
      max-width: 250px;
      cursor: pointer;
      span{
        font-size: 25px;
      }
      
    }
    
    &.box-prix-buy {
      cursor: pointer;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to right, #007e8e, #005477);
      opacity: .7;
      border-radius: 25px;
    }

    h3 {
      text-align: center;
      font-weight: 800;
      font-size: 32px;
      color: #8aff00;
      line-height: 1;
      position: relative;
      z-index: 9;

      span {
        font-size: 30px;
      }
    }

    h4 {
      color: #ff87d0;
      font-size: 32px;
      font-weight: 800;
      text-align: center;
      line-height: 1;
      position: relative;
      z-index: 9;
      margin: 0;

      span {
        font-size: 22px;
      }
    }

    h5 {
      color: #53ebfc;
      font-size: 28px;
      font-weight: 800;
      position: relative;
      z-index: 9;
      text-align: center;
    }
  
  }

  .img-dog {
    width: 250px;
    max-width: 80%;
    margin: auto auto 0;
    display: block;
    @media #{$small} { 
      margin-top: 10px;
    }
  }

  .wtop-bar {
    position: relative;

    .top-bar {
      max-width: 80%;
      margin: 0 auto 20px;
      width: 33.85vw;
      height: 11.05vw;
      background: url(../../assets/tema-cachorro/osso.png) center no-repeat;
      background-size: 100% auto;
      text-align: center;
      color: #0a5e00;
      font-size: 20px;
      font-weight: 800;
      cursor: pointer;
  
      span {
        color: #a6008e;
      }
    }

    .wLastWinners {
      position: absolute;
      top: calc(100% + 5px);
      background: #FFF;
      z-index: 99;
      border-radius: 10px;
      left: 0;
      width: 100%;
      display: none;
      flex-direction: column;

      &.active {
        display: flex;
      }

      .close {
        width: 20px;    
        height: 20px;
        display: block;
        top: 0;
        cursor: pointer;
        align-self: flex-end;
        padding: 3px;
        background: #FFF;
        z-index: 9;
        position: absolute;
        margin: 8px 10px;

        &:after,
        &:before {
          content: '';
          width: 100%;
          height: 3px;
          background: #F00;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
        }

        &:after {
          transform: translate(-50%, -50%) rotate(135deg);
        }
      }

      .lastWinners {
        height: 400px;
        overflow-y: auto;
        padding: 15px;

        .row {
          padding: 8px 2px;
          text-align: center;
          font-size: 10px;
          color: #0a5e00;
  
          &.head {
            border-bottom: 1px solid #333;
            font-weight: bold;

            .col {
              word-break: keep-all;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          &.body:nth-of-type(odd) {
            background: #ddd;
          }
  
          .col {
            word-break: break-all;
            margin: 0;
          }
        }
      }

    }

  }


  .ball-screen-phrase {
    margin: 0 30px 10px;
    color: #fff;
    background: linear-gradient(to right, #007e8e, #005477);
    border-radius: 5px;
    padding: 10px;
  }


  .buy-cards {

    display: grid;
    grid-template-columns: repeat(2, 160px);
    grid-gap: 30px;
    justify-content: center;
    margin-top: 15px;

    .item {
      height: 100px;
      background: url(../../assets/tema-cachorro/bg-card.png);
      background-size: cover;
      margin: 0 0 50px;
      cursor: pointer;

      h3 {
        text-align: center;
        font-size: 22px;
        line-height: 1.2;
        font-weight: 800;
        color: #ff87d0;

        span.blue {
          color: #faae00;
        }
      }
    }
  }

  @media #{$notebook-down} {
    .wrap-chat {
      max-width: 100%;
      height: 580px;

      .chat {
        height: 535px;
      }
    }

    .box-prix {
      h4 {
        font-size: 28px;
      }
    }
  }


  @media #{$intermediate-down} {
    .wrap-chat {
      height: 520px;

      /*
      .chat {
        height: 495px;
        padding: 5px 37px 5px 42px;
      }
      */
    }
  }

  @media #{$large-down} {
    .wtop-bar {
      .top-bar {
        height: 9vw;
        width: 18vw;
        font-size: 12px;
        margin: 0 auto 10px;
      }
    }

    .wrap-chat {

      margin: 0 auto;
      position: relative;
      width: 305px;
      height: 445px;
      padding: 15px 0 10px;

      &::after {
        left: 0;
        height: calc(100% - 52px);
        width: 27px;
        top: 26px;
      }
  
      &::before {
        right: 0;
        height: calc(100% - 52px);
        width: 26px;
        top: 26px;
      }
  
  
      .top { 
        width: 100%; 
        height: 27px; 
        top: 0;
        left: 0;
      }
  
      .bottom { 
        width: 100%; 
        height: 27px; 
        bottom: 0;
        left: 0;
      }

      .wrap-talk {
        z-index: 9;

        &.showmore {
          height: 175px;
        }
      }
    }

    .wlast-balls {
      margin: 0 0 20px;

      h1 {
        font-size: 24px;
        margin: 0 0 15px;
      }

      .balls {
        min-height: 70px;
        width: auto;

      }
    }

    .buy-cards {
      grid-template-columns: repeat(1, 175px);
      justify-content: center;

      .card  {
        margin: 0 auto 15px;
      }

      .item {
        height: 100px;
        margin: 0 auto 15px;
      }
    }

    .box-prix {
      margin: 0;

      h3 {
        font-size: 26px;
      }

      h4 {
        font-size: 22px;
      }
    }

  }

  @media #{$small} {

    .buy-cards {
      grid-template-columns: repeat(1, 1fr);
      justify-content: center;
      grid-gap: 10px;

      .card  {
        margin: 0 auto;
        max-width: 150px;
      }

      .item {
        height: 85px;
        margin: 0 auto;
        max-width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: auto 100%;
        max-width: 225px;

        h3 {
          font-size: 18px;
        }
      }
    }

    .wtop-bar {

      .top-bar {
        height: 20vw;
        width: 100%;
        font-size: 10px;
        max-width: 70%;
        width: 100%;
        margin: 0 auto 10px;
      }

      .wLastWinners {
        width: 100vw;
        left: -37vw;
      }
      
    }

    .wrap-chat {
      max-width: 100%;
      height: 80vw;

      .chat {
        /*
        width: calc(100% - 40px);
        height: 73vw;
        top: 25px;
        left: 20px;
        min-width: auto;
        */
        padding: 0 10px 0 25px;
        width: 100%;
        height: 100%;

        .timeline {
          margin: 10px 0;
          padding-bottom: 40px;
        }

        .wrap-talk {
          bottom: auto;
          height: 60px;
          width: calc(100% - 35px);
          position: absolute;
          bottom: -5px;
          left: 26px;

          &.showmore {
            height: 175px;
          }

          .talk {
            background: #feeeec;
            border-radius: 5px;
          }
        }
      }

      .chatMessage__avatar {
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;

        .icon {
          width: 9px;
          height: 9px;
        }
      }

      .chatMessage__name {
        line-height: 1;
        margin: 0;
        font-size: 9px;
      }
      
      .chatMessage__text {
        line-height: .9;
        font-size: 11px;
      }

      .bubble {
        font-size: 9px;
        line-height: .9;
        padding: 2px 4px;
      }
    }

    .ball-screen-phrase {
      margin: 0 auto;
      font-size: 10px;
      padding: 5px 10px;
      max-width: 170px;
      text-align: center;
    }

    .box-prix {
      padding: 10px;
      min-height: auto;

      &.sound {
        max-width: 150px;
        span {
          font-size: 16px;
        }
        margin-bottom: 10px;
      }

      h3 {
        font-size: 14px;

        span {
          font-size: 12px;
        }
      }

      h4 {
        font-size: 14px;
        margin: 0;

        span {
          font-size: 12px;
        }
      }

    }

    .wlast-balls {
      margin: 0 0 10px;

      h1 {
        font-size: 18px;
        margin: 0 0 10px;
      }

      .balls {

        .ball {
          width: 40px;
          height: 58px;
          font-size: 22px;  

          &.ball1 {
            width: 50px;
            height: 65px;
          }
        }
      }
    }

  }

}
@import "../../../styles/variables.scss";

#modal-game-cards {

  .modal-content {
    display: flex;
    height: 100%;
    align-items: center;

    @media #{$large-down} {
      height: auto;
    }

  }

  .container {
    padding: 0;
  
    .wmenu {
  
      .menu {
        border-radius: 15px 15px 0 0;
        padding: 45px 35px 30px;
  
        &.menu-1 {
          background: #41a500;
  
          span {
            text-shadow: -1px -1px 0 #ff4200,  1px -1px 0 #ff4200, -1px 1px 0 #ff4200, 1px 1px 0 #ff4200;
          }
        }
        
        &.menu-2 {
          background: #ff4200;
          
          span {
            text-shadow: -1px -1px 0 #41a500,  1px -1px 0 #41a500, -1px 1px 0 #41a500, 1px 1px 0 #41a500;
          }
        }
  
        span {
          color: #FFF;
          margin: 0 15px 0 0;
          font-weight: 800;
          font-size: 20px;
        }
  
        img {
          max-height: 32px;
        }
      }
    }
  
    .wtabs {
      background: #d2d2d2;
      border-radius: 0 0 15px 15px;
  
      .tab {
        padding: 0 140px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 520px;

        .container-error {
          min-height: 70px;

          .msg { 
            margin: 5px 0;
           }
        }
  
        .item {
  
          img {
            border-radius: 15px;
            max-width: 80%;
            display: block;
            margin: 0 0 15px;
          }
  
          .value {
            font-size: 19px;
            color: #545450;
            font-weight: 600;
            margin: 0 0 15px;
  
            img {
              display: block;
              margin: 0 0 0 10px;
              border-radius: 0;
            }
          }
  
          .input {
            width: 100px;
            height: 70px;
            background: #FFF;
            border: none;
            text-align: center;
            font-size: 30px;
            color: #545450;
            font-weight: 600;
          }
        }
  
        .row-btns  {
          margin: 15px 0 0;
        }
  
        .button {
          width: 140px;
          line-height: 60px;
          background-color: #4c0b85;
          margin: 0 25px;
          border: none;
  
          span {
            font-size: 20px;
            color: #FFF;
            font-weight: 600;
          }
        }
  
        .btn-link {
          border: none;
          background: none;

          .link {
            font-weight: 600;
            display: block;
            margin: 0 25px;
            font-size: 20px;
            color: #fff;
            background: #7ccb00;
            line-height: 60px;
            padding: 0 15px;
            border-radius: 10px;
          }
        }
      }
    }
  
    @media #{$large-down} {

      padding: 50px 15px;
  
      .wmenu {
  
        .menu {
          width: 100%;
          margin: 0 0 15px;
          padding: 15px;
          border-radius: 15px;
  
          &.menu-2 {
            margin: 0;
          }
  
          span {
            font-size: 18px;
  
          }
  
        }
  
      }
      
      .wtabs  {
  
        .tab {
          padding: 25px 35px;
  
          .item {
            margin: 0 0 50px;
  
            .value {
              margin: 0 0 25px;
            }
  
            img {
              max-width: 175px;
            }
  
          }         
  
          .link {
            font-size: 18px;
            margin: 0 15px;
          }
  
          .button {
            margin: 0 15px;
            width: 180px;
            line-height: 45px;
  
            span {
              font-size: 16px;
            }
          }

          .btn-link {
  
            .link {
              font-size: 16px;
              line-height: 1.1;
              height: 45px;
              display: flex;
              align-items: center;
              margin: 0;
            }
          }

          .row-btns {
            padding: 20px 0;
            background: #d2d2d2;
            position: sticky;
            bottom: 0;
            left: 0;
            width: 100%;
          }

          .container-error {
            min-height: auto;
          }

        }
  
      }
  
    }
  
  }
}

@import "../../styles/variables.scss";

.buttonComoJogar {
  cursor: pointer;
  color: #FFF;
  margin: auto;
  font-size: 18px;
  width: 180px;
  @media #{$large-down} {
    text-align: center;
    font-size: 16px;
  }
  margin-top: 20px;
  background-color: orange;
  border-radius: 10px;
  height: 50px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wComoJogar {


  .content{
    background: #fff;
    height: 500px;
    position: absolute;
    top: 42%;
    transform: translate(-50%, -50%);
    z-index: 999999;
    width: 500px;
    display: none;
    border-radius: 25px;
    box-shadow: -2px 3px 9px 1px rgba(0,0,0,0.46);

    @media #{$large-down} {
      width: 90vw;
      top: 500px;
    }

    .close {
      width: 20px;    
      height: 20px;
      display: block;
      top: 0;
      cursor: pointer;
      align-self: flex-end;
      padding: 3px;
      background: #FFF;
      z-index: 9;
      position: absolute;
      margin: 8px 10px;
      right: 5px;

      &:after,
      &:before {
        content: '';
        width: 100%;
        height: 3px;
        background: #F00;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:after {
        transform: translate(-50%, -50%) rotate(135deg);
      }
    }

    .text {
      padding: 20px;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      width: 100%;
      @media #{$large-down} {
        font-size: 14px;
      }
    }

    &.active {
      display: flex;
    }

  }
  
}
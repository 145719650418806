@charset "utf-8";
@import "./variables.scss";
@import "./base.scss";
@import "./grid.scss";

.wrapper {
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	position: relative;
	//padding-bottom: 290px;
	@media #{$large-down} { 
		//padding-bottom: 105vw;
	}

	@media only screen and (max-width: $breakpoint-default) {
		height: auto;
		overflow: hidden;
	}

	.maintenance {
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		height: 100vh;

		.logo {
			svg { 
				max-width: 400px;
				max-height: 200px;
			} 
		 }

		 h2, h3 {
			padding: 0 10px;
			text-align: center;
		 }
	}

	.container {
		width: 1280px;
		max-width: 90%;
		margin: 0 auto;

		@media #{$large-down} {
			max-width: 100%;
			padding: 0 15px;
		}
	}

	.institucional {
		padding: 50px 0;

		h1 {
			font-size: 28px;
			font-weight: 800;
			text-align: center;
			margin: 0 0 35px;
		}

		p {
			font-size: 16px;
			line-height: 1.4;
			margin: 0 0 15px;
			color: #373737;

			&.center {
				margin: 30px 0 15px;
				text-align: center;
				font-size: 22px;
				font-weight: 600;
			}
		}
	}

	.main {
		flex-grow: 1;
		display: grid;
		grid-template-columns: auto 43vh 43vh;
		min-height: calc(100vh - 115px);

		@media #{$large-down} {
			display: flex;
			flex-direction: column;
			min-height: calc(100vh - 170px);
		  }

		.balls-drown-container {
			display: none;
		}

		&-logged {
			grid-template-columns: auto 140px 420px;

			.balls-drown-container {
				display: block;
			}
		}

		@media only screen and (max-width: $breakpoint-default) {
			grid-template-columns: 1fr;
			
			.stream {
				height: 70vw;
			}

		}

		& > div {
			height: 100%;
		}
	}
}

.float-whatsapp {
	position: fixed;
	bottom: -50px;
	right: 15px;
	z-index: 999;
	transition: all .25s;

	&.active {
		bottom: 15px;
	}

	@media #{$intermediate-down} {
		right: 10px;

	}

	img {
		max-width: 45px;
	}
}

#react-confirm-alert {
	
	.react-confirm-alert-body {
		font-family: $default-font;
		text-align: center;
		background: #7ccb00;
		border-radius: 0;
		color: #FFF;
		font-size: 14px;

		h1 {
			color: #FFF;
		}
	
		.react-confirm-alert-button-group {
			display: flex;
			justify-content: center;

			button {
				background: #4f0b85;
				border-radius: 0;
				font-size: 14px;
				font-weight: 600;
			}
		}
	}
}

.sign-up-modal {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media #{$large-down} {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
  }

  img {
    width: 100%;
    max-width: 485px;
    display: block;
  }
}

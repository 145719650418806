@import "./Videobingo.scss";

.theme-gatinho {
  padding: 50px 0;
  background: url(../../assets/tema-gatinho/bg.png) top center no-repeat;
  background-size: cover;


  .chat .wrap-talk{
    z-index: 12;
  }
  
  .chat .wrap-talk .talk, .chat .wrap-talk .show-messages {
    background-color: #f0d8bb;
  }

  .wtop-bar {
    position: relative;
    margin: 0 0 80px;
    width: calc(100% - 80px);

    .top-bar {
      width: 100%;
      padding: 20px 0;
      border-radius: 25px;
      text-align: center;
      color: #d6499b;
      font-size: 26px;
      font-weight: 800;
      background: linear-gradient(to right, #9cd369, #d1f8c6, #9cd369);
      margin: 0 0 10px;
    }

    .wLastWinners {
      position: absolute;
      top: calc(100% + 5px);
      background: #FFF;
      z-index: 99;
      border-radius: 10px;
      left: 0;
      width: 100%;
      display: none;
      flex-direction: column;
  
      &.active {
        display: flex;
      }
  
      .close {
        width: 20px;    
        height: 20px;
        display: block;
        top: 0;
        cursor: pointer;
        align-self: flex-end;
        padding: 3px;
        background: #FFF;
        z-index: 9;
        position: absolute;
        margin: 8px 10px;
        right: 0;
  
        &:after,
        &:before {
          content: '';
          width: 100%;
          height: 3px;
          background: #F00;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
        }
  
        &:after {
          transform: translate(-50%, -50%) rotate(135deg);
        }
      }
  
      .lastWinners {
        height: 400px;
        overflow-y: auto;
        padding: 15px;
  
        .row {
          padding: 8px 2px;
          text-align: center;
          font-size: 10px;
          color: #0a5e00;
  
          &.head {
            border-bottom: 1px solid #333;
            font-weight: bold;
  
            .col {
              word-break: keep-all;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
  
          &.body:nth-of-type(odd) {
            background: #ddd;
          }
  
          .col {
            word-break: break-all;
            margin: 0;
          }
        }
      }
    } 

  }

  .box-prix {
    padding: 10px 30px;
    position: relative;
    margin: 25px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    user-select: none;

    &.box-prix-buy {
      margin: 25px 0 auto;
      @media #{$large-down} { 
        margin: 0;
      }
    }

    span.counter {
      color: #a854fd;
    }

    &.sound {
      min-height: auto;
      margin: 0 auto;
      max-width: 250px;
      cursor: pointer;

      span{
        font-size: 25px;
      }
      

      &:after {
        display: block;

      }
    }
    
    &.box-prix-buy {
      cursor: pointer;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to right, #dbe5a7, #95b438);
      opacity: .7;
      border-radius: 25px;
      display: none;
    }

    h3 {
      text-align: center;
      font-weight: 800;
      font-size: 32px;
      color: #468400;
      line-height: 1;
      position: relative;
      z-index: 9;

      span {
        font-size: 30px;
      }
    }

    h4 {
      color: #d9469d;
      font-size: 32px;
      font-weight: 800;
      text-align: center;
      line-height: 1;
      position: relative;
      z-index: 9;
      margin: 0;

      span {
        font-size: 22px;
      }
    }

    h5 {
      color: #53ebfc;
      font-size: 28px;
      font-weight: 800;
      position: relative;
      z-index: 9;
      text-align: center;
    }
  
  }

  .wrap-chat {
    width: calc(100% - 80px);
    height: 42vw;
    max-height: 550px;
    padding: 50px 23px 0 25px;
    padding: 0 0 0 15px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: -6px;
      height: calc(100% - 70px);
      width: 29px;
      background: url(../../assets/tema-gatinho/b1.png);
      background-size: 100% 100%;
      top: 62px;
      z-index: 11;
    }

    &::before {
      content: '';
      position: absolute;
      right: -63px;
      height: calc(100% - 70px);
      width: 87px;
      background: url(../../assets/tema-gatinho/b2.png);
      background-size: 100% 100%;
      top: 62px;
      z-index: 11;
    }

    .orelha-1,
    .orelha-2 {
      position: absolute;
      width: 5vw;
      height: 7vw;
      background: url(../../assets/tema-gatinho/orelha-1.png);
      background-size: 100% auto;
      background-repeat: no-repeat;
      left: -.5vw;
      top: -3.25vw;
    }

    .orelha-2 {
      left: auto;
      right: -1vw;
      transform: scaleX(-1);
    }

    .top { 
      position: absolute; 
      width: calc(100% + 9px); 
      height: 65px; 
      background: url(../../assets/tema-gatinho/t1.png);
      background-size: 100% 100%;
      top: 0;
      left: 0;
      z-index: 10;
    }

    .bottom { 
      position: absolute; 
      width: 100%; 
      height: 15px; 
      background: url(../../assets/tema-gatinho/t2.png);
      background-size: 100% 100%;
      bottom: -3px;
      left: 0;
      z-index: 2;
    }

    .chat {
      height: 100%;
      width: calc(100% - 20px);
      padding: 50px 5px 5px;

      .wrap-talk {
        width: 101%;
        height: 65px;

      }
    }

    .area-messages {
      width: 100%;
      height: 30vw;
      margin: 0 0 35px;
    }

    .wrap-talk {
      input {
        border: none;
      }
    }
  }

  .wlast-balls {

    h1 {
      color: #057689;
    }

    h3 {
      color: #468400;

    }

    .balls {
      padding: 0 10px;
      width: 22vw;
      margin: 0 auto;

      .ball {
        width: 4.75vw;
        height: 5vw;
        background: url(../../assets/tema-gatinho/green-dot.png) center no-repeat;
        background-size: 100% auto;
        color: #FFF;

        &.ball1 {
          width: 5.75vw;
          height: 8.5vw;
          background: url(../../assets/tema-gatinho/pink-dot.png) center no-repeat;
          background-size: 100% auto;
          transform: scale(1.1);
        }
      }
    }
  }

  .img-espinha {
    display: block;
    max-width: 70%;
    margin: 0 auto 20px;
  }

  .ball-screen-phrase {
    margin: 10px 15px;
    color: #d6499b;
    background: linear-gradient(to right, #9cd369, #d1f8c6, #9cd369);
    border-radius: 5px;
    padding: 10px;
    font-size: 14px;
  }

  .buy-cards {

    display: grid;
    grid-template-columns: repeat(2, 160px);
    grid-gap: 30px;
    justify-content: center;
    margin-top: 15px;

    .item {
      width: 100%;
      height: 100px;
      display: block;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      cursor: pointer;

      @for $i from 0 through 3 {
        $imageName: '../../assets/tema-gatinho/comprar'+($i+1)+'.png' ;
        &.item-#{$i} {
          background-image: url(#{$imageName});
        }

      }
    }
  }

  .img-gato {
    display: block;  
    max-width: 250px;
    width: 100%;
    margin: 0 auto 50px;
  }

  @media #{$notebook-down} {

    .wtop-bar {
      margin: 0 auto 40px;

      .top-bar {
        font-size: 22px;

      }
    }

  }

  @media #{$large-down} {
    .wtop-bar {
      width: calc(100% - 60px);

      .top-bar {
        height: auto;
        max-width: 400px;
        margin: 0 auto 50px;
      }
    }

    .img-espinha {
      width: 375px;
      max-width: 80%;
      margin: 0 auto;
    }

    .wrap-chat {

      max-width: 400px;
      height: 53vw;
      margin: 0 auto;
      position: relative;
      padding: 0;

      .wrap-talk {
        &.showmore {
          height: 175px;
        }
      }

    }

    .wlast-balls {
      h1 {
        font-size: 18px;
        margin: 0 0 10px;
      }

      h3 {
        margin-bottom: 5px;
      }

      .balls {
        width: 100%;
 
        .ball {
          width: 45px;
          height: 60px;
          margin: 0 5px;

          &.ball1 {
            width: 50px;
            height: 80px;
          }
        }
      }     
    }
  }

  @media #{$large-down} {
    .wtop-bar {

      .top-bar {

        margin: 10px auto;
      }
    }

  }

  @media #{$small} {

    .container {
      padding: 0 15px 0 5px;
    }

    .wlast-balls {
      h1 {
        order: 1
      }
      h3 {
        order: 0;
      }
      .balls {
        order: 2;
      }

    }

    padding: 15px 0 50px;

    .col-cards {
      padding: 0;
      width: 40%;
    }

    .wtop-bar {
      margin: 0 auto 15px;
      width: calc(100% - 20px);

      .top-bar {
        font-size: 13px;
        width: 100%;
      }
    }

    .img-cat {
      width: 250px;
      max-width: 80%;
      margin: auto auto 0;
      display: block;
      @media #{$small} { 
        margin-top: 10px;
      }
    }

    .wrap-chat {
      max-width: 100%;
      height: 95vw;
      padding: 40px 0 0 10px;
      width: calc(100% - 30px);
      margin: 0 0 0 10px;

      &:after {
        width: 16px;
        height: calc(100% - 50px);
        left: -3px;
        top: 37px;
      }

      &:before {
        top: 37px;
        width: 50px;
        height: calc(100% - 50px);
        right: -50px;
      }

      .top {
        height: 40px;
        width: calc(100% + 19px);
      }

      .bottom {
        bottom: 0;
      }

      .chat {
        width: 100%;
        top: 0;
        padding: 0;
      }

      .orelha-1,
      .orelha-2 {
        width: 40px;
        height: 60px;
        top: -20px;

        &.orelha-2 {
          right: -6vw;  
        }
      }  
    }

    .buy-cards {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 25px;

      .item {
        width: 100%;
        margin: 0;
        height: 75px;
      }
    }

    .box-prix {
      padding: 0 10px;
      min-height: auto;
      margin: 0;
      padding: 15px 0;

      h3 {
        font-size: 12px;
        margin: 0 0 10px;

        span {
          font-size: 18px;

        }
      }

      br {
        display: none;
      }

      h4 {
        font-size: 14px;
        margin: 0 0 10px;

        span {
          font-size: 18px;

        }
      }

      &.sound {
        max-width: 150px;
        margin: 0 auto;
        padding: 10px;

        h3 {
          font-size: 14px;
          margin: 0;
    
          span {
            font-size: 14px;
    
          }
        }
      }
    }

    .wtop-bar {

      .wLastWinners {
        width: 97vw;
        left: -40.5vw;
      }

      .top-bar {
        width: 100%;
        font-size: 13px;
        border-radius: 15px;
        padding: 15px 0;
        margin-bottom: 10px;
      }
    }

    .ball-screen-phrase {
      margin: 10px;
      font-size: 12px;
    }

    .img-gato {
      margin: 0 0 15px;
      max-width: 70px;
    }
  }
}